@font-face {
  font-family: "PT-Regular";
  src: url(./fonts/PTSans-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PT-Italic";
  src: url(./fonts/PTSans-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "PT-Mono";
  src: url(./fonts/PTMono-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  background-color: #111;
}